import * as React from 'react';
import Application from 'next/app';
import Head from 'next/head';
import {
  i18next,
  withAmplifyAuth,
  withApollo,
  withI18next,
  withLDProvider,
} from '@r360-tours/core';
import { LAUNCH_DARKLY_KEY } from '@r360-tours/core/lib/launch-darkly-key';
import { Theme } from '@r360-tours/ui';
import resources from '@r360-tours/ui/i18n-resources';
import Main from '../components/main';
import { GA_TRACKING_ID } from '../lib/gtag';
import { GTM_CONTAINER_ID } from '../lib/gtm';

class Authenticator extends Application {
  public render(): JSX.Element {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <title>{i18next.t('App.Title')}</title>
          <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link
            href="https://fonts.googleapis.com/css?family=Mukta+Vaani:300,400,600"
            rel="stylesheet"
          />
          <script src="/marzipano/marzipano.js" />
          {/* Google Tag Manager */}
          <script
            type="application/javascript"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');
          `,
            }}
          />
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <script
            type="application/javascript"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('set', {'cookie_flags': 'SameSite=None;Secure'});
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              send_page_view: false
            });
          `,
            }}
          />
        </Head>
        <Theme>
          <Main>
            <Component {...pageProps} />
          </Main>
        </Theme>
      </>
    );
  }
}

export default withLDProvider({ clientSideID: LAUNCH_DARKLY_KEY })(
  withI18next(
    withAmplifyAuth(
      withApollo(Authenticator, {
        disableAuthRedirect: true,
      }),
    ),
    resources,
  ) as React.ComponentType,
);
